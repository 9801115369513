export enum ApiModule {
	ARCHIVES = 'archives',

	DIRECTORIES = 'directories',

	PERMISSIONS = 'permissions',
	PERMISSIONS_ALL = 'permissions-all',
	PRINT_JOBS = 'print_jobs',

	PROJECT = 'project',
	PROJECT_ACTIVITIES = 'project_activities',
	PROJECT_API = 'project_api',
	PROJECT_FOLDER_FILE_HISTORY = 'project_folder_file_history',
	PROJECT_FOLDER_FILES = 'project_folder_files',
	PROJECT_FOLDER_PERMISSIONS = 'project_folder_permissions',
	PROJECT_FOLDERS = 'project_folders',
	PROJECT_USER_TYPES = 'project_user_types',
	PROJECT_USERS = 'project_users',
	PROJECTS = `${ApiModule.PROJECT}s`,

	QUICK_FILLING = 'quick_filling',

	REPORTS = 'reports',

	SEND_DATA = 'quick_filling',

	SETTINGS_ACCESS = 'settings_access',
	SETTINGS_ADVERTISEMENTS = 'settings_advertisements',
	SETTINGS_COMPANIES = 'settings_companies',
	SETTINGS_LOGS = 'settings_logs',
	SETTINGS_PRINT_JOBS = 'settings_print_jobs',
	SETTINGS_PROFILE = 'settings_profile',
	SETTINGS_PROJECTS = 'projects',
	SETTINGS_REPORTS = 'settings_reports',
	SETTINGS_ROLES = 'settings_roles',
	SETTINGS_TENANTS = 'settings_tenants',
	SETTINGS_THEMES = 'settings_themes',
	SETTINGS_USERS = 'settings_users',

	THEMES = 'themes',

	USERS = 'users',
}
